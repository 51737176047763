import React from "react";
import { Link } from "react-scroll";

export default function Header() {
  return (
    <header>
      <nav className="flex justify-between items-center py-4 px-8 bg-dark">
        <div className="flex items-center justify-between w-full text-white flex-wrap">
        <div className="flex items-center  gap-3">
          <img src="/assets/favicon.png" alt="logo" className="w-8 h-8 object-contain"/>
          <a href="/" className="text-2xl font-bold hidden md:block">
          Najlepsze hotele z kasynem w Polsce
          </a>
          </div>
          <ul className="flex ml-10 space-x-4 text-sm font-bold">
           
            <li>
              <a href="#hotels">Najlepsze hotele z kasynem</a>
            </li>
            <li>
            <Link
                to="contacts"
                spy={true}
                smooth={true}
                offset={-70} // Adjust this value if needed to align with your design
                duration={500}
                className="bg-mainAccent p-2 rounded text-white cursor-pointer"
              >
               Skontaktuj się z nami
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
