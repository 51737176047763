const casinoHotels = [
  {
    name: 'Kasyno Warszawa',
    description: 'Światowej klasy ośrodek oferujący luksusowe zakwaterowanie, wykwintne restauracje i ogromną powierzchnię kasyna.',
    image: '/assets/img-1.webp',
    link: 'https://www.kasynowarszawa.pl/',
    rating: '9.2 / 10',
    price: 'Od 1000 zł za noc'
  },
  {
    name: 'Kasyno Kraków',
    description: 'Doświadcz najwyższej rozrywki z najlepszymi udogodnieniami i zapierającymi dech w piersiach widokami na Kraków.',
    image: '/assets/img-2.webp',
    link: 'https://www.kasynokrakow.pl/',
    rating: '8.9 / 10',
    price: 'Od 900 zł za noc'
  },
  {
    name: 'Kasyno Gdańsk',
    description: 'Premiowe miejsce na mapie Gdańska oferujące doskonałe połączenie relaksu i emocji.',
    image:  '/assets/img-4.webp',
    link: 'https://www.kasynogdansk.pl/',
    rating: '8.7 / 10',
    price: 'Od 800 zł za noc'
  },
  {
    name: 'Kasyno Wrocław',
    description: 'Luksusowy wypoczynek we Wrocławiu z najwyższej klasy kasynem, wykwintnymi restauracjami i spektakularnymi widokami.',
    image:  '/assets/img-3.avif',
    link: 'https://www.kasynowroclaw.pl/',
    rating: '9.0 / 10',
    price: 'Od 950 zł za noc'
  },
  {
    name: 'Kasyno Poznań',
    description: 'Ciesz się światem emocji i luksusu w Kasynie Poznań, oferującym rozległą powierzchnię do gier i ekskluzywne zakwaterowanie.',
    image: '/assets/img-5.jpg',
    link: 'https://www.kasynopoznan.pl/',
    rating: '8.8 / 10',
    price: 'Od 850 zł za noc'
  },
  {
    name: 'Kasyno Łódź',
    description: 'Położone w pięknym zabytkowym budynku, Kasyno Łódź oferuje wyrafinowane doświadczenie gier z luksusowym zakwaterowaniem i opcjami gastronomicznymi.',
    image:  '/assets/img-6.jpg',
    link: 'https://www.kasynolodz.pl/',
    rating: '8.5 / 10',
    price: 'Od 750 zł za noc'
  }
];

export default casinoHotels;
