import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import casinoHotels from './data/casinoHotels';
import { Button } from '@headlessui/react';
import Contacts from './components/Contacts';

const HomePage = () => {
  return (
    <>
      <Header />
      <div className="container mx-auto my-20 px-4">
        <header className="text-center my-20">
          <h1 className="text-4xl font-bold">Najlepsze Hotele Kasynowe w Polsce</h1>
          <p className="mt-4 text-lg">
            Odkryj najlepsze hotele kasynowe oferujące luksus, rozrywkę i ekscytację w całej Polsce.
          </p>
        </header>

        <section className="my-8">
          {infoBlocks.map((block, index) => (
            <div
              key={index}
              className="group flex flex-col lg:flex-row items-center my-20 transition-transform transform hover:scale-105"
            >
              {index % 2 === 0 ? (
                <>
                  <img
                    src={block.image}
                    alt={block.title}
                    className="w-full lg:w-1/2 h-80 object-cover rounded-lg shadow-lg"
                  />
                  <div className="p-6 lg:w-1/2 md:mb-4">
                    <h3 className="text-2xl font-semibold mb-4 group-hover:text-blue-500 uppercase">{block.title}</h3>
                    <p className="text-gray-800 text-left">{block.description}</p>
                  </div>
                </>
              ) : (
                <>
                  <div className="p-6 lg:w-1/2  md:mb-4">
                    <h3 className="text-2xl font-semibold mb-4 group-hover:text-blue-500 uppercase">{block.title}</h3>
                    <p className="text-gray-800 text-left">{block.description}</p>
                  </div>
                  <img
                    src={block.image}
                    alt={block.title}
                    className="w-full lg:w-1/2 h-80 object-cover rounded-lg shadow-lg"
                  />
                </>
              )}
            </div>
          ))}
        </section>

        <section className="my-8" id="hotels">
          <h2 className="text-3xl font-semibold mb-4">Najlepsze hotele z kasynem</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {casinoHotels.map((hotel, index) => (
              <div
                key={index}
                className="border rounded-lg p-4 shadow-md text-left transition-transform transform hover:scale-95"
              >
                <img
                  src={hotel.image}
                  alt={hotel.name}
                  className="w-full h-48 object-cover rounded-t-lg"
                />
                <h3 className="text-2xl font-semibold mt-4">{hotel.name}</h3>
                <p className="text-gray-800 mt-2">{hotel.description}</p>
                <a
                  href={hotel.link}
                  className="text-blue-500 hover:underline mt-4 block"
                >
                  Ucz się więcej
                </a>
              </div>
            ))}
          </div>
        </section>
      </div>
      <Contacts />
      <Footer />
    </>
  );
};

const infoBlocks = [
  {
    title: 'O Polsce',
    description: 'Polska to kraj pełen pięknych krajobrazów, różnorodnej fauny i flory oraz dynamicznych miast. Od gór Tatr po Mazury, Polska oferuje niezliczone atrakcje dla każdego. Kraj ten znany jest z gościnności, bogatej kultury i różnorodnych tradycji. Niezależnie od tego, czy szukasz relaksu na plaży, zwiedzania zabytków czy aktywnego wypoczynku na świeżym powietrzu, Polska ma coś dla każdego. Zróżnicowany klimat, od umiarkowanego po surowszy, zapewnia różnorodne doświadczenia przez cały rok.',
    image: '/assets/img-4.webp'
  },
  {
    title: 'Główne Miasta',
    description: 'Odwiedź tętniące życiem miasta Polski, takie jak Warszawa, Kraków, Wrocław i Gdańsk, każde oferujące unikalny charakter i atrakcje. Warszawa, znana z nowoczesnej architektury i bogatej historii, przyciąga turystów z całego świata. Kraków, kulturalna stolica Polski, słynie z historycznego centrum i licznych muzeów. Wrocław oferuje malownicze widoki i liczne atrakcje nad rzeką Odrą. Gdańsk, miasto o bogatej tradycji morskiej, zachwyca pięknymi plażami i zabytkowymi budowlami. Każde z tych miast ma coś wyjątkowego do zaoferowania, czyniąc Polskę fascynującym miejscem do odwiedzenia.',
    image: '/assets/img-1.webp'
  },
  {
    title: 'Luksusowe Hotele',
    description: 'Polska oferuje wiele luksusowych hoteli, które zapewniają doskonałą obsługę, wyśmienite jedzenie i nowoczesne udogodnienia. Te hotele często znajdują się w strategicznych lokalizacjach, oferując zapierające dech w piersiach widoki i łatwy dostęp do głównych atrakcji. Goście mogą korzystać z najwyższej klasy spa, centrów fitness i gourmet restauracji. Dbałość o szczegóły i indywidualne podejście do klienta sprawiają, że każdy pobyt jest niezapomniany. Niezależnie od celu podróży, luksusowe hotele w Polsce gwarantują komfort i wyrafinowanie.',
    image: '/assets/img-2.webp'
  },
  {
    title: 'Hotele Kasynowe',
    description: 'Doświadcz emocji związanych z grą i komfortu luksusu w najlepszych polskich hotelach kasynowych, oferujących nowoczesne udogodnienia i rozrywkę. Te hotele łączą luksusowe zakwaterowanie z różnorodnymi opcjami gier, w tym automatami, grami stołowymi i pokerowymi. Wiele hoteli kasynowych oferuje również występy na żywo, wykwintne posiłki i ekskluzywne usługi VIP. Dynamiczna atmosfera i najwyższej klasy obsługa zapewniają ekscytujący i przyjemny pobyt. Niezależnie od tego, czy jesteś doświadczonym graczem, czy odwiedzasz kasyno po raz pierwszy, polskie hotele kasynowe oferują coś dla każdego.',
    image: '/assets/img-3.avif'
  }
];

export default HomePage;
